import { IFeature } from "./feature-flag-interfaces";

export const PULSE_LANDING_PAGE_V2: IFeature = { name: "pulse-landing-page-v2", page: "home" };
export const PULSE_2FA_FLAG: IFeature = { name: "pulse-2fa-flag", page: "home" };
export const GRID_MODE_EXPANSION: IFeature = { name: "grid-mode-expansion", page: "pi-general-info" };
export const ROUTING_TO_GRID_MODE: IFeature = { name: "routing-to-grid-mode", page: "pi-general-info" };
export const GERMANY_POST_AMNOG: IFeature = { name: "germany-post-AMNOG", page: "pi-price_grid-mode-p-&-r" };
export const AVAILABILITY_MAP: IFeature = { name: "availability-map", page: "pi-general-info" };
export const SOC_INDEX: IFeature = { name: "soc-index", page: "pi-reimbursement-&-hta" };
export const PRODUCT_SELECTOR_V2: IFeature = { name: "product-selector-v2", page: "indications-page" };
export const AVERAGE_BODY_WEIGHT: IFeature = { name: "avg-body-weight", page: "measure-filter" };
export const BODY_SURFACE_AREA: IFeature = { name: "body-surface-area", page: "measure-filter" };
export const COT_LEVEL: IFeature = { name: "cot-level", page: "measure-filter" };
export const COT_CAL_BUTTON: IFeature = { name: "cot-cal-button", page: "COT" };
export const MEASURES_FILTER_PRESETS: IFeature = { name: "measures-filter-preset", page: "filters" };
export const BRAND_FILTER_PRESETS: IFeature = { name: "brand-filter-preset", page: "filters" };
export const AVAILABILITY_MAP_LAUNCHDATE: IFeature = { name: "availability-map-launchdate", page: "pi-general-info" };
export const USA_ADDITIONAL_PRICE: IFeature = { name: "usa-additional-prices", page: "home" };
export const INSTANT_INSIGHT: IFeature = { name: "instant-insights", page: "home" };
export const CHAT_GPI: IFeature = { name: "chat-gpi", page: "home" };
export const USA_HISTORICAL: IFeature = { name: "usa-historical", page: "usa-additional" };
export const DATA_MINER: IFeature = { name: "data-miner", page: "home" };
export const Global_Launch_Sequence: IFeature = { name: "global-launch-sequence", page: "home" };
export const Payer_Insights: IFeature = { name: "payer-insights", page: "home" };
