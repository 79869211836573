import { AccessPagesEnum, AtlasTabIds } from './enums';

export const RoutesEnum = {
  Home: 'home',
  AtlasPage: 'atlas',
  AtlasIrpMatrix: 'irp-matrix',
  AtlasTabs: {
    Glance: AtlasTabIds[AtlasTabIds.glance],
    Facts: AtlasTabIds[AtlasTabIds.facts],
    Pricing: AtlasTabIds[AtlasTabIds.pricing],
    Irp: AtlasTabIds[AtlasTabIds.irp],
    Sources: AtlasTabIds[AtlasTabIds.sources]
  },
  Horizon: 'horizon',
  Project: 'project',
  Projects: 'projects',
  Insights: 'insights',
  Indications: 'indications',
  Builder: 'builder',
  MSAD: 'msad',
  ExchangeRates: 'exchange-rates',
  FAQ: 'faq',
  PlatformUserAuthorization:'platform-user-authorization',
  InstantInsights: 'instant-insights',
  UsaAdditionalPrices:'usa-additional-prices',
  DataMiner:'data-miner',
  NewChat: "new-chat",
  ChatGpi: "chat-gpi"
};

export const HorizonRoutesEnum = {
  ProjectDetails: '/project-details/:id',
};

export const UserAccessPages = [
  { key: 'cot', value: 'Cost Of Treatment' },
  { key: 'price', value: 'Price' },
  { key: 'reimbursement', value: 'Reimbursement & HTA' },
  { key: 'journey', value: 'Journey Details' },
  { key: 'projects', value: 'Projects' },
  { key: 'instant-insights', value: 'Instant Insights' },
  { key: RoutesEnum.AtlasPage, value: AccessPagesEnum.profiles },
  { key: RoutesEnum.AtlasPage, value: AccessPagesEnum.irp },
  { key: RoutesEnum.AtlasIrpMatrix, value: AccessPagesEnum.irp }
];

export const PlaceholderText = {
  Moa: 'Search by brand name, active ingredient, mechanism of action, top-level indication or WHOATC code…',
  CountryProfile: 'Search by brand name, active ingredient, country name, top-level indication or WHOATC code…',
  MoaAndCountryProfile: 'Search by brand name, active ingredient, country name, mechanism of action, top-level indication or WHOATC code…',
  Default: 'Search by brand name, active ingredient, top-level indication or WHOATC code…'
};

export const DialogText = {
  DataExportProgressMessage: 'Data export is in progress',
  CountryFilterClearMessage: 'Please confirm you want to clear the country filter selection.',
  DefaultPresetSaveMessage: 'Please name your preferences',
  DefaultPresetDeleteMessage: 'Please confirm you would like to delete preference',
  MeasuresPresetSaveMessage: 'Please name your dosing preferences',
  BrandFilterClearMessage: 'Please confirm you want to clear the brand filter selection.',
  BrandGroupSaveMessage: 'Please name your brand selection',  
}

export const CanadaNames = {
  CanadaFederal: 'Canada Federal',
  CanadaQuebec: 'Canada Quebec',
  CanadaOntario: 'Canada-ontario',
  Canada: 'Canada'
};

export const SerbiaNames = {
  Serbia: 'Serbia',
  RepublicOfSerbia: 'Republic of Serbia',
};

export const CountryNames = {
  USA: 'USA',
  Germany: 'Germany',
  England: 'England',
  UnitedKingdom: 'United Kingdom',
  Scotland: 'Scotland',
  China: 'China',
};

export const PriceColorCodes = {
  Published: 'Published',
  Calculated: 'Calculated',
  Estimated: 'Estimated'
};

export const InfoButtonText = {
  GeneralInfo: 'Initial launch date refers to the first price date for the product of interest (irrespective of indication approval) within the basket of countries selected in your filter.',
  IndicationMatrix: 'A checkmark signifies the commercial availability (i.e. pricing data) and indication approval across any of the countries selected in the filter.',
  FormulationsPackDetails: 'The Default Pack label is applied to the pack most readily available across the countries selected in the filter for each Brand.',
  COTCorridor: 'The calculations below represent the cheapest cost of treatment (COT) for a given product/indication in each country. COT calculations are based on country-specific label information taking into account loading dose and maintenance dose frequency and duration of treatment (see Dosage information tab for details).',
  COTComparison: 'COT Comparisons are calculated based on the Brands with the lowest and highest COT within a top-level indication (based on the products selected in the filter)​ \n \nSelected drug: The cheapest COT for that Brand/indication in that country.​ \n \nMin: The cheapest COT for any Brand within the basket of products selected in the filter.​ \n \nMax: The most expensive COT for any Brand within the basket of products selected in the filter​.',
  COTHistory: 'The data is dependent on the combination of filters selected. Changing the filters may display further data (e.g. indication).',
  DosageInfo: 'The dosage information for the indication of interest as detailed in the country-specific label.',
  ReimMap: 'The map displays the status of the most recent reimbursement/HTA decision, or the most recent favourable decision issued for each Brand within the top-level indication.​​ \n \nCountries with no available information on the reimbursement status are grey. This does not reflect the drug’s regulatory status or pricing availability.',
  TimeToReim: 'Time to reimbursement is calculated as the time from regulatory approval until HTA report publication or national reimbursement decision.',
  ReimAndHTA: 'Show latest HTA displays the latest HTA decision for each Brand per country in the top-level indication (TLI) of interest. If mixed decisions for the same brand and TLI were issued, the latest positive decision is shown here.​ \n \nShow all current HTA displays all available HTA decisions for this top-level indication, except for those revoked, replaced or outdated.\n \nShow non-HTA displays all available regulatory and reimbursement data (HTA Decisions or Formulary Status) for each brand and/or country irrespective of HTA availability. This includes countries for which no HTA decisions are issued, as well as countries where HTA assessments are still pending. HTA decisions take precedence over national level (formulary status) reimbursement if both are available for a given brand/country/indication. ',
  JourneyDetails: 'Pack prices and COT are displayed in the currency selected in the filter.\n \nGreen (positive), red (negative) and orange (reimbursed with restrictions) events on the timeline reflect the status of the HTA/reimbursement decisions.\n \nBlack (neutral) events represent new pack launches, IRP rule changes, VAT change, and/or margin rule changes.\n \nThe data is dependent on the combination of filters selected. Changing the filters may display further data (e.g. indication).',
  ReimAndHTAComparator: 'This data is captured from clinical trials used for HTA submissions, and provides insights on the most commonly used comparators or potentially the standard of care (SoC) in each of these countries. The results will depend on the products in your Brands filter, and this can be used to make the data more specialized/focused on a specific subset of analogues, or more comprehensive by including all Brands associated with a top-level indication (TLI).'
};

export const TooltipText = {
  MaxBrandsExceededWidgetMode: 'Whilst more than 15 brands are selected, widget mode is disabled',
  DefaultPresetSaveTooltip: '',
  DefaultPresetDeleteTooltip: '',
  MeasuresPresetSaveTooltip: 'Save measures selection',
  MeasuresPresetDeleteTooltip: 'Delete measures selection',
};

export const CalcCOTFilter = {
  month: 'Monthly',
  annum: 'Annual',
  day: 'Daily',
  cycle: 'Per Cycle',
  ddd: 'Per DDD',
  pack: 'Per Pack',
  mg: 'Per MG',
  unit: 'Per Unit',
  includeWastage: 'including wastage',
  excludeWastage: 'excluding wastage'
};

export const HTACogOptions = {
  all: 'all',
  latest: 'latest',
  nonHTA: 'nonHTA'
};

export const DecisionColNames = {
  allAndLatestDecision: 'Decision (Standardized)',
  nonHTADecision: 'Decision/Reimbursement Status'
};

export const Decision = {
  FormularyStatusNotReimbursed: 'Not Reimbursed (Formulary Status)',
  FormularyStatusReimbursed: 'Reimbursed (Formulary Status)',
  Available: 'Available',
};


export const HospitalReimbCountries={
  Sweden:'Sweden',
  Finland:'Finland',
  Luxembourg:'Luxembourg',
  Austria:'Austria'
}

export const ErrorPageText = {
  InsufficientPermissionsMessage: 'Insufficient permissions',
  InternalErrorMessage: 'Internal error',
  ConnectivityIssueMessage: 'Connectivity issue',
};

export const ErrorStatusCodes = {
  ServiceUnavailable: '503',
  InsufficientPermissions: '403',
};


export const Brandpreference ={
  Brandpreference :'brandpreference;'
}