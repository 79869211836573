import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../profile/menu/user.profile';
import { environment } from '../../environments/environment';

interface ChatHistory {
  Today?: any; // Replace `any` with the actual type of the data, e.g., `string[]` or `Message[]`
  Yesterday?: any;
  "Last 30 days"?: any;
  [key: string]: any; // Allows additional keys with the same value type
}

@Component({
  selector: 'he-chat-history',
  standalone: true,
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.css'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      state(
        'out',
        style({
          transform: 'translateX(-100%)',
          opacity: 0,
        })
      ),
      transition('in => out', [animate('300ms ease-in-out')]),
      transition('out => in', [animate('300ms ease-in-out')]),
    ]),
  ],
  imports: [CommonModule], // Add CommonModule here
})
export class ChatHistoryComponent {
  @Input() page: string = 'gpi-home';
  @Input() updateHistoryData: boolean = false;
  @Input() newConversation: string | null = '';
  selectedConversationId: string | null = null;

  // @Input() historyData!: Observable<any>; // Observable for historyData
  // currentHistoryData: any = {}; // Local copy for display

  userEmail: string = '';
  loading: boolean = false;
  historyData: any = {};
  historyDataKey: any = [];

  API_URL = environment.chatGpiBeEndpoint;
  // API_URL = "http://localhost:8000/";

  constructor(
    private router: Router,
    private http: HttpClient,
    private userProfile: UserProfile
  ) {
    this.userEmail = this.userProfile.getEmail();
  }

  ngOnInit() {
    // Subscribe to the observable input and update the component's data
    // this.historyData.subscribe((data) => {
    //   this.currentHistoryData = data;
    //   console.log('History data updated:', data);
    // });
    this.fetchHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updateHistoryData) {
      this.fetchHistory();
    }
    if (changes.newConversation) {
      this.selectedConversationId = this.newConversation;
    }
  }

  getTokenFromSessionStorage() {
    const allKeys = Object.keys(sessionStorage);
  
    for (const key of allKeys) {
      const sessionData = sessionStorage.getItem(key);
  
      if (sessionData) {
        try {
          const parsedData = JSON.parse(sessionData);
          console.log("parsedData:", parsedData);
  
          if (parsedData && parsedData.credentialType === "AccessToken") {
            return parsedData.secret;
          }
        } catch (error) {
          continue;
        }
      }
    }
  
    return null; 
  }

  fetchHistory(): void {
    this.loading = true;
    this.http
      .get<any>(`${this.API_URL}/chat-history`, {
        headers: {
          Authorization: `Bearer ${this.getTokenFromSessionStorage()}`
        }
      })
      .subscribe(
        (response) => {
          if (response) {
            const historyData: Partial<ChatHistory> = {}
            if(response.Chat_history?.Today){
              historyData["Today"] = response.Chat_history?.Today;
            }
            if(response.Chat_history?.Yesterday){
              historyData["Yesterday"] = response.Chat_history?.Yesterday;
            }
            if(response.Chat_history["Last 30 days"]){
              historyData["Last 30 days"] = response.Chat_history["Last 30 days"];
            }

            // Add only keys that are not already in the object
            Object.keys(response.Chat_history || {}).forEach(key => {
              if (!["Today", "Yesterday", "Last 30 days"].includes(key)) {
                historyData[key] = response.Chat_history[key];
              }
            });
            this.historyData = historyData; // Update Chat History
            this.historyDataKey = Object.keys(historyData);
          } else {
            console.warn('No response or empty data');
          }
          this.loading = false; // End loading state after data retrieval
        },
        (error) => {
          console.error('Error fetching chat history:', error);
          this.loading = false; // Ensure loading is stopped on error
        }
      );
  }

  // ngOnInit(): void {

  // }

  isHistoryCollapsed: boolean = false; // Initial state

  toggleHistory() {
    this.isHistoryCollapsed = !this.isHistoryCollapsed;
  }

  // Method to navigate to the new chat page
  newChat() {
    this.router.navigate(['/chat-gpi']);
  }

  // Method to select a chat item
  selectItem(itemId: string) {
    this.selectedConversationId = itemId;
    this.router.navigate(['/new-chat'], {
      queryParams: {
        conversation_id: itemId,
      },
    });
  }

  get animationState() {
    return this.isHistoryCollapsed ? 'out' : 'in';
  }
  // Helper function to get keys of the object
  getKeys(data: any) {
    return Object.keys(data);
  }
}
