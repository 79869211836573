export const environment = {
  production: false,
  environmentName: 'dev',
  tawkUrl: 'https://embed.tawk.to/602a4b08918aa261273ef147/1euija6oo',
  oAuth2Endpoint: 'https://2fafe.dev.gpi-pulse.com/',
  gatewayApiEndpoint: 'https://pulsebe.dev.gpi-pulse.com/api',
  gatewayApiEndpointHorizon: 'https://horizonbe.dev.gpi-pulse.com/api',
  gatewayApiEndpointImportExport: 'http://10.4.0.112',
  modelApiEndpoint: '__env:dev:modelApiEndpoint__',
  chatGpiBeEndpoint: 'https://chatgpibe.dev.gpi-pulse.com/', 
  b2cPolicies: {
    clientId: '9834dd61-b0e2-4c96-97e7-6298f291c6ff',
    authorityDomain: 'heliosdev.b2clogin.com',
    names: {
      signUpSignIn: 'B2C_1_sisu',
      forgotPassword: 'B2C_1_passwordreset',
      editProfile: 'B2C_1_editprofile'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_signin',
      },
      forgotPassword: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_passwordreset',
      },
      editProfile: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_editprofile'
      }
    }
  },
  apiConfig: {
    scopes: ['https://heliosdev.onmicrosoft.com/00192ae6-9d2b-41b1-a253-1353b6b7bfa8/datastore.query'],
    uri: 'https://pulsebe.dev.gpi-pulse.com/api/*'
  },
  horizonApiConfig: {
    scopes: ['https://heliosdev.onmicrosoft.com/00192ae6-9d2b-41b1-a253-1353b6b7bfa8/datastore.query'],
    uri: 'https://horizonbe.dev.gpi-pulse.com/api/*',
  },
  appInsights: {
    instrumentationKey: 'ec6ba82b-e816-4742-92d4-d94b9196ffb0',
    dependencyLogging: 'false',
    pageViewLogging: 'false',
  },
  googleAnalytics: {
    key: 'G-K8WXPM1KHY'
  },
  builderIo: {
    key: '24944d6fb316458e849c0b56150ca145'
  }
};
