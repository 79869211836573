import {
  FilterMeasuresPeriodEnum,
  FilterProductParamsBrandedStatusEnum,
  FilterProductParamsOrphanStatusEnum,
  FilterProductParamsReimbursementEnum
} from '../../datastore/interfaces';

export interface AnalogSelectionFilterInterface {
  topLevelIndication: string;
  therapyArea: string;
  moa: string;
  mahDateFrom: string | null;
  mahDateTo: string | null;
  prevalenceBucket: string;
  pricePer: FilterMeasuresPeriodEnum;
  priceMin: number | null;
  priceMax: number | null;
  reimbursementStatus: FilterProductParamsReimbursementEnum;
  orphanStatus: FilterProductParamsOrphanStatusEnum;
  brandedStatus: FilterProductParamsBrandedStatusEnum;
  roa: string;
  atcCodes: string[];
  lineOfTherapy: FilterLOTEnum;
  comparator: boolean;
  combination: FilterAnalogCombinationEnum;
  countries: string[];
}

export enum AnalogSelectionFilterNamesEnum {
  topLevelIndication = 'topLevelIndication',
  therapyArea = 'therapyArea',
  moa = 'moa',
  mahDateFrom = 'mahDateFrom',
  mahDateTo = 'mahDateTo',
  prevalenceBucket = 'prevalenceBucket',
  pricePer = 'pricePer',
  priceMin = 'priceMin',
  priceMax = 'priceMax',
  reimbursementStatus = 'reimbursementStatus',
  orphanStatus = 'orphanStatus',
  brandedStatus = 'brandedStatus',
  roa = 'roa',
  atcCodes = 'atcCodes',
  lineOfTherapy = 'lineOfTherapy',
  comparator = 'comparator',
  combination = 'combination',
  countries = 'countries'
}

export enum FilterLOTEnum {
  all = 'all',
  first = '1st +',
  second = '2nd +',
  third = '3rd +',
  adjuvant = 'Adjuvant',
  preventive = 'Preventive',
  palliative = 'Palliative',
  diagnostic = 'Diagnostic',
  otherUnspecified = 'Other/unspecified',
}

export enum FilterAnalogCombinationEnum {
  all = 'All',
  combined = 'Combined',
  notCombined = 'Not combined',
}


export enum DataMinerFilterEnum {
  indication = 'Indication'
}
