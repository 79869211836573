import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionDataStorageService } from '../session-data-storage.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PlatformUserAuthorizationDataService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  private readonly sessionKey = 'platformAuthCode';

  constructor(
    private storage: SessionDataStorageService,
    private http: HttpClient,
    private router: Router
  ) {}

  setPlatformUserAuthCodeInSession(authCode: string) {
    this.storage.setLocalStorageItem(this.sessionKey, authCode);
  }

  getPlatformUserAuthCodeFromSession() {
    return this.storage.getLocalStorageItem(this.sessionKey);
  }

  removePlatformUserAuthCodeFromSession() {
    this.storage.removeLocalStorageItem(this.sessionKey);
  }

  verifyAndUpdateUserAuthorizationCode(
    authorizationCode: string,
    baseUserAccount: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/PlatformAudit/VerifyAndUpdateUserAuthorizationCode?authorizationCode=${authorizationCode}&&baseUserAccount=${baseUserAccount}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (error) => reject(error),
        });
    });
  }

  getPlatformUserAuditInfo(authorizationCode: string, baseUserAccount: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/PlatformAudit/GetPlatformUserAuditInfo?authorizationCode=${authorizationCode}&&baseUserAccount=${baseUserAccount}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (error) => reject(error),
        });
    });
  }

  deleteAuthorizationCode(authorizationCode: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${this.projectAPIUri}/PlatformAudit/DeleteAuthorizationCode?authorizationCode=${authorizationCode}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (error) => reject(error),
        });
    });
  }

  async isAuthorizationValid(baseUserAccount: string) {
    const authCode = this.getPlatformUserAuthCodeFromSession();
    let isAuthValid = false;
    let isDemoAccount = this.isDemoAccount(baseUserAccount);

    if (authCode) {
      try {
        const authData: any = await this.getPlatformUserAuditInfo(
          authCode,
          baseUserAccount
        );

        const loggedBaseUserAccount = authData.data.baseUserAccount;
        isAuthValid = baseUserAccount === loggedBaseUserAccount ? true : false;
      } catch (e) {
        console.log(e);
        isAuthValid = false;
      }
    }
    if (isDemoAccount && !isAuthValid) {
      //console.log(isDemoAccount, isPlatformUserAuthenticated);
      this.router.navigate(['/', 'platform-user-authorization']);
      return false;
    }

    return true;
  }

  private isDemoAccount(loggedInUserEmail: string) {
    const keywords = ['_uat', 'demo', 'test', '_trial'];

    let isDemoAccount = false;
    keywords.forEach((keyword) => {
      if (loggedInUserEmail.toLocaleLowerCase().indexOf(keyword) > -1) {
        isDemoAccount = true;
        return;
      }
    });

    return isDemoAccount;
  }
}
